import { TonConnectUI } from '@tonconnect/ui';
import Vue from 'vue';
import App from './App.vue';
// import router from './router';
import store from './store';
import Clipboard from './directive/copy'
import i18n from './i18n'
import Env from './env'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'
import {
  Button,
  Popover,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  Loading,
  Message,
  Notification,
  Tooltip,
  Alert,
  Tag,
  Dialog, Form, FormItem, InputNumber,
} from 'element-ui'
// import SolanaWallets from "solana-wallets-vue";

// // You can either import the default styles or create your own.
// import "solana-wallets-vue/styles.css";

// import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

// import {
//   PhantomWalletAdapter,
//   // SlopeWalletAdapter,
//   SolflareWalletAdapter,
// } from "@solana/wallet-adapter-wallets";

// const walletOptions = {
//   wallets: [
//     new PhantomWalletAdapter(),
//     // new SlopeWalletAdapter(),
//     new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
//   ],
//   autoConnect: true,
// };

// Vue.use(SolanaWallets, walletOptions)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
})
Vue.use(Clipboard)
Vue.use(VueAnalytics, {
  id: 'UA-141655829-1',
  disableScriptLoader: true,
  autoTracking: {
    pageviewOnLoad: false
  }
})
Vue.use(Form)
Vue.use(FormItem)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Popover)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Progress)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Loading)
Vue.use(Env)

Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.prototype.$tonConnect = new TonConnectUI({
  manifestUrl: 'https://invoice.mugglepay.com/tonconnect-manifest.json',
  restoreConnection: true,
})

import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
// 右下角组件
/* !function () {
  (function () {
    var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
    s1.id = 'hs-script-loader'
    s1.async = true;
    s1.src = '//js-na1.hs-scripts.com/44448279.js';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
}() */
new Vue({
  // router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
