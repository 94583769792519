<template>
    <div class = "status-container">
        <div class="status-box">
            <img src="/mediacryptos/info.svg" alt="">
            <p class = "status-text">
                {{ $t('Underpayment') }}
            </p>
        </div>
        <p class = "status-describe">
            {{ $t('UnderpaymentTextPre') }} <span class = "payType">{{ changePayType2KeyLabel(payType) }}</span> {{ $t('UnderpaymentTextNext') }}
        </p>
        <div class="items-container hznKoK">
            <copy-item :labelText = "$t('AmountRequested')" :contentText = "accountText" noneCopy = "true"></copy-item>
            <copy-item :labelText = "$t('AmountReceived')" :contentText = "accountRecievedText" noneCopy = "true"></copy-item>
            <copy-item :labelText = "$t('needToSend')" :contentText = "accountRemainText"></copy-item>
            <copy-item :labelText = "`${changePayType2KeyLabel(payType)} ${$t('Address')}`" :contentText = "handleAddress(addressText)" :copyText = "addressText"></copy-item>
        </div>
        <order-num></order-num>
        <back></back>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import CopyItem from '../components/CopyItem'
    import OrderNum from '../components/OrderNum'
    import Back from '../components/Back'
    export default {
        components: {
            CopyItem,
            OrderNum,
            Back
        },
        computed: {
            ...mapState({
                accountText: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.pay_amount || ''
                },
                accountRecievedText: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.crypto_info && state.orderData.invoice.crypto_info.paidAmount || ''
                },
                accountRemainText: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.crypto_info && (state.orderData.invoice.pay_amount - state.orderData.invoice.crypto_info.paidAmount).toFixed(2) || ''
                },
                addressText: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.address || state.orderData.invoice.qrcode || ''
                },
                payType: (state) => state.payType,
                payTypeList: (state) => state.payTypeList
            })
        },
        methods: {
            changePayType2KeyLabel(data) {
                let active
                if(data) {
                    active = this.payTypeList.filter((pay) => {
                        if(data.includes('USDT')) {
                            return pay.name == 'USDT'
                        } else {
                            return pay.key == data
                        }
                    })[0]
                } else {
                    active = ''
                }
                return active && active['keyLabel']
            },
            handleAddress(data) {
                return data.slice(0, 6) + '...' + data.slice(data.length - 4)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .status-container {
        width: 100%;
    }
    .hznKoK {
        background-color: rgba(0, 0, 0, 0.01);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .payType {
        color: #55b5ff;
    }
    /deep/ {
        .el-alert {
            background-color: #fff!important;
            justify-content: center;
            .el-alert__icon {
                font-size: 32px;
                margin-right: 20px;
            }
            .el-alert__title {
                color: #222222;
                font-size: 18px;
            }
            .el-alert__closebtn {
                display: none;
            }
        }
    }
    .status-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
    }
    .status-describe {
        margin-bottom: 40px;
        text-align: justify;
    }
    .status-text {
        font-size: 18px;
    }
    /deep/ .gDmTff {
        &:first-child {
            span {
                margin-right: 10px;
            }
        }
    }
</style>