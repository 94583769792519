<template>
    <div class = "status-container">
        <order-num></order-num>
        <el-progress type="circle" :percentage="curProgress"></el-progress>
        <div class="status-text" v-if = "curProgress == 0">
            {{ $t('DetectingPayment') }}
        </div>
        <div class="status-text" v-else>
            {{ $t('Verifying') }}
        </div>
        <div class = "status-describe-text" v-if = "curProgress == 0" :class = "{cn: $i18n.locale == 'zh'}">
            {{ $t('DetectingPaymentText') }}
        </div>
        <div class = "status-describe-text" :class = "{cn: $i18n.locale == 'zh'}" v-else>
            {{ $t('SuccessfullyWaiting') }}
        </div>
        <!-- 链接后续加入 -->
        <p class="operate-btn" v-if = "curProgress != 0 && transaction_id">
            <a :href="`https://etherscan.io/tx/${transaction_id}`" target = "_blank">{{ $t('ViewPayment') }}</a>
            <img src="/mediacryptos/pending.svg" alt="">
        </p>
        <back></back>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import OrderNum from '../components/OrderNum'
    import Back from '../components/Back'
    import { getParameterByName } from '../utils'
    export default {
        components: {
            OrderNum,
            Back
        },
        computed: {
            ...mapState({
                curProgress: (state) => {
                    let invoice = state.orderData.invoice
                    let percent = 0
                    if(!invoice) {
                        return 0
                    } else {
                        if(invoice.crypto_info) {
                            percent = (invoice.crypto_info.pendingBlock.confirmations * 100 / invoice.crypto_info.pendingBlock.confirmations_required)
                        }
                    }
                    return percent
                },
                transaction_id: (state) => {
                    let invoice = state.orderData.invoice
                    if(invoice && invoice.crypto_info) {
                        return invoice.crypto_info.pendingBlock && invoice.crypto_info.pendingBlock.transaction_id || ''
                    } else {
                        return ''
                    }
                }
            })
        },
        watch: {
            curProgress(val) {
                if(val && val == 1) {
                    // 改变状态
                    this.getData()
                }
            }
        },
        methods: {
            ...mapActions([
                'getOrderData'
            ]),
            getData() {
                let order = getParameterByName('id')
                if (!order) {
                    return
                }
                this.getOrderData(order).then((res) =>{
                    if(res.data) {
                        this.setOrder(res.data)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .status-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .status-text {
            text-align: center;
            margin: 20px 0px;
            font-size: 18px;
        }
        .operate-btn {
            text-align: center;
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                text-decoration: none;
                margin-right: 6px;
            }
            img {
                width: 16px;
            }
        }
    }
    /deep/ .el-progress-circle {
        margin: 0 auto;
    }
    .status-describe-text {
        text-align: justify;
        &.cn {
            text-align: center;
        }
    }
</style>