import { JettonWallet } from '@/utils/jettonwallet';
import { getHttpEndpoint } from '@orbs-network/ton-access';
import { Address, beginCell } from '@ton/core';
import { JettonMaster, toNano, TonClient } from '@ton/ton';

export class TonConnect {
  tonConnectUI;
  wallet;

  tokens = {
    usdt: {
      name: 'Tether USD',
      symbol: 'USD₮',
      address: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
      decimal: 6
    },
    not: {
      name: 'Notcoin',
      symbol: 'NOT',
      address: 'EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT',
      decimal: 9
    }
  }

  constructor(connector) {
    this.tonConnectUI = connector

    this.tonConnectUI.onStatusChange((wallet) => {
      this.wallet = wallet;
    })
  }

  get account() {
    return this.wallet.account;
  }

  get address() {
    return this?.account ? Address.parse(this?.account?.address) : null;
  }

  async connect() {
    return new Promise(async (resolve, reject) => {
      // Subscribe to the event that indicates wallet connection status change
      if (this.tonConnectUI.connected) {
        this.wallet = this.tonConnectUI.wallet;
        resolve(this.address)
      } else {
        await this.tonConnectUI.openModal();
        const unsubscribe = this.tonConnectUI.onModalStateChange((state) => {
          try {
            if (state.closeReason === 'wallet-selected' && this.address) {
              resolve(this.address); // Resolve the Promise with the connection status
              unsubscribe(); // Unsubscribe after resolving to avoid memory leaks
            } else if (state.closeReason === 'action-cancelled') {
              reject('User denied wallet connection');
              unsubscribe(); // Unsubscribe after resolving to avoid memory leaks
            }
          } catch (error) {
            reject(error.message); // Reject the Promise if subscription fails
            unsubscribe(); // Unsubscribe after resolving to avoid memory leaks
          }
        });
      }

    });
  }

  async disconnect() {
    if (this.tonConnectUI.connected) {
      this.tonConnectUI.disconnect()
    }
  }

  async sendTransaction({ senderAddress, receiverAddress, amount, memo }) {
    return new Promise(async (resolve, reject) => {
      try {
        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(memo ?? '') // write our text comment
          .endCell();

        const payload = {
          validUntil: Math.floor(Date.now() / 1000) + 600,
          messages: [
            {
              // The receiver's address.
              address: receiverAddress,
              // Amount to send in nanoTON. For example, 0.005 TON is 5000000 nanoTON.
              amount: String(toNano(amount)),
              payload: body.toBoc().toString('base64'), // payload with comment in body
            },
          ],
        };

        this.tonConnectUI
            .sendTransaction(payload)
            .then(() => {
              this.tonConnectUI.closeSingleWalletModal();
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
      } catch (e) {
        reject(e)
      }
    })
  }

  async sendJettonTransaction({ receiverAddress, amount, memo }, token = 'usdt') {
    return new Promise(async (resolve, reject) => {
      try {
        const currentToken = this.tokens[token]
        const endpoint = await getHttpEndpoint({ network: 'mainnet' });
        const tonClient = new TonClient({ endpoint })
        const jw = new JettonWallet()

        const LEDGER_MASTER_ADDRESS = Address.parse(currentToken.address);
        const RECEIVER_ADDRESS = Address.parse(receiverAddress);

        const jettonMaster = tonClient.open(new JettonMaster(LEDGER_MASTER_ADDRESS));
        const usersJettonAddress = await jettonMaster.getWalletAddress(this.address);

        const jettonWallet = tonClient.open(jw.createFromAddress(usersJettonAddress));

        const sender = {
          send: async (args) => {
            await this.tonConnectUI.sendTransaction({
              messages: [
                {
                  address: args.to.toString(),
                  amount: args.value.toString(),
                  payload: args.body?.toBoc()?.toString('base64'),
                },
              ],
              validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
            });
          },
          address: this.address,
        }

        jettonWallet
          .sendTransfer(sender, {
            fwdAmount: 1,
            comment: memo ?? '',
            jettonAmount: BigInt(amount * Math.pow(10, currentToken.decimal)),
            toAddress: RECEIVER_ADDRESS,
            value: toNano('0.05'), // Gas fee 0.02 TON
          })
          .then(() => {
            this.tonConnectUI.closeSingleWalletModal();
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reject(e)
      }
    })
  }
}
