<template>
  <div class="sc-cqPOvA gDmTff">
    <div class="sc-gacfCG iyvklx" id="payment-amount" width="100%">
      <div class="sc-gNJABI dIpdpx sc-dEfkYy cdvOpu">
        <label class="sc-jxGEyO ladqwM sc-hzDkRC fsyfQm sc-bRBYWo fDApnR">{{ labelText }}</label>
        <div class="sc-dEfkYy cdvOpu">
          <span>{{ contentText }}</span>
          <span v-if="!noneCopy">
            <el-button type="text" @click="doCopy">{{ $t("COPY") }}</el-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["labelText", "contentText", "noneCopy", "copyText"],
  data() {
    return {
      tipVisible: false,
    };
  },
  methods: {
    async doCopy() {
      try {
        await navigator.clipboard.writeText(this.copyText);
        this.$message({
          message: this.$t("COPYED"),
          type: "success",
        });
      } catch (err) {
        this.$message({
          message: "Failed to Copy Text.",
          type: "error",
        });
      }
    },
    onError() {},
  },
  watch: {
    tipVisible(val) {
      if (val) {
        setTimeout(() => {
          this.tipVisible = false;
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hznKoK > div:first-child {
  border-top: none;
  text-align: right;
}
.gDmTff {
  height: auto;
  text-align: left;
  position: relative;
  & + .gDmTff {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.iyvklx {
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  font-size: 16px;
}
.cdvOpu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.dIpdpx {
  height: 100%;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.fDApnR {
  margin-bottom: 4px;
  font-size: 14px;
}
.fsyfQm {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ladqwM {
  line-height: 20px;
  font-weight: 500;
  color: rgb(78, 92, 110);
  white-space: nowrap;
  margin: 0px 10px 0px 0px;
}
.cdvOpu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  &.sc-dEfkYy {
    span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 160px;
      text-align: right;
      // padding-right: 10px;
    }
  }
}
.lebKsc {
  display: inline-block;
  position: relative;
  color: inherit;
  background-color: transparent;
}
.dWrbqB {
  padding: 0px 10px;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: rgb(140, 205, 254);
  cursor: pointer;
}
</style>
<style lang="scss">
.copy-tips {
  background: #222222 !important;
  color: #fff !important;
  text-align: center !important;
  .popper__arrow {
    &::after {
      border-top-color: #222222 !important;
    }
  }
}
</style>
