<template>
  <div :class="`auto-wallet-container ${principalId ? 'with-wallet' : ''}`">
    <!-- Payment Detail-->
    <vue-qr :text="qrText" :size="200" class="qrcode" v-if="!principalId"></vue-qr>

    <p class="pay-tips">
      {{ $t('SendPaymentWithinPre') }}
      <span class="remain-time">{{ remainTime }}</span>
      {{ $t('SendPaymentWithinNext') }}
    </p>
    <div v-if="(networks || []).length > 0" class="tabs-container">
      <template v-for="(item) in networks">
        <div v-if="item.show"
             :class="{active: payType === item.key}"
             @click="changeNetwork(item.key)"
             :key="item.key">
          <span>{{ item.label }}</span>
          <img :src="item.icon" :alt="item.label"/>
        </div>
      </template>
    </div>

    <p class="pay-tips">
      <span>{{ $t('makePaymentPre') }}</span>
      <img :src="currentMethod?.icon" :alt="currentMethod?.currencyLabel"/>
      <span>{{ currentMethod?.currencyLabel }}&nbsp;</span>
      <span>{{ $t('makePaymentNext') }}</span>
    </p>

    <div class="address-information">
      <copy-item :labelText="$t('Amount')"
                 :contentText="payAmount + ' ' + currentMethod?.currencyLabel"
                 :copyText="payAmount"></copy-item>
      <copy-item :labelText="currentMethod?.label + ` ${$t('Address')}`"
                 :contentText="shortAddr(addressText)"
                 :copyText="addressText && addressText.trim()"
                 v-if="!principalId"></copy-item>
    </div>

    <!-- Connect Identity-->
    <div class="wallet-information">
      <template v-if="principalId">
        <p class="address">
          {{ `${$t('wallet.connected')}: &nbsp;${briefAddr}` }}
        </p>
        <div class="balance" v-if="currentAsset">
          <p>Balance: {{ balance }} {{ currentAsset?.label }}</p>
          <el-button type="text" @click="receiveDialog = true">Receive</el-button>
        </div>

        <el-button plain
                   @click="payDialog = true"
                   :loading="isLoading"
                   :disabled="transaction?.insufficientBalance"
                   :class="`send-btn ${transaction?.insufficientBalance ? 'disabled' : ''}`">
          {{ transaction?.insufficientBalance ? 'Insufficient Balance' : $t('send') }}
        </el-button>
        <span @click="disconnect()"
              class="btn-disconnect">Disconnect Wallet</span>
      </template>

      <el-button plain v-else @click="connectWallet()" :loading="isLoading" class="connect-btn">Connect Your Internet
        Identity
      </el-button>
    </div>

    <!-- Modal Dialog Section -->
    <el-dialog
        :title="`Receive ${this.payType}`"
        :visible.sync="receiveDialog"
        :show-close="true"
        :custom-class="'receive-dialog'">
      <div class="account-information">
        <vue-qr :text="accountId" :size="230" class="qrcode"></vue-qr>
        <el-alert
            :closable="false"
            type="info">
          <p class="alert">Copy Account ID for sending from exchanges and Principal ID for lCP network.</p>
        </el-alert>

        <div class="account-id">
          <el-tag size="small">Account ID</el-tag>
          <span>{{ accountId }}
            <i class="el-icon-copy-document copy-act"
               v-clipboard:copy="accountId"
               v-clipboard:success="onCopy"
               :key="accountId"></i></span>
        </div>
        <div class="account-id">
          <el-tag size="small" type="warning">Principal ID</el-tag>
          <span>{{ principalId }}
              <i class="el-icon-copy-document copy-act"
                 v-clipboard:copy="principalId"
                 v-clipboard:success="onCopy"
                 :key="principalId"></i></span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="receiveDialog = false">Done</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="Confirm Payment"
        :visible.sync="payDialog"
        :show-close="!payLoading"
        :custom-class="'pay-dialog'"
        :width="'450px'"
    >
      <div class="transaction-info">
        <div class="section">
          <span class="label">Amount:</span>
          <span class="value">{{ transaction.payAmount }} {{ currentMethod?.currencyLabel }} ({{
              `${order && order.pay_currency !== 'USD' ? '¥' : '$'}${(transaction.orderAmount || 0)}`
            }})</span>
        </div>
        <div class="section">
          <span class="label">Transaction Fee:</span>
          <span class="value">{{ transaction.fee }} {{ currentMethod?.currencyLabel }}</span>
        </div>
        <div class="section">
          <span class="label">Total</span>
          <span class="value">{{ transaction.total }} {{ currentMethod?.currencyLabel }}</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <p>Your Balance: {{ balance }} {{ currentAsset?.label }}</p>
        <el-button @click="doPayment" type="primary" :disabled="transaction?.insufficientBalance" :loading="payLoading">
          {{ transaction?.insufficientBalance ? 'Insufficient Balance' : 'Pay' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Api from '@/api';
import CopyItem from '@/components/CopyItem.vue';
import { getParameterByName } from '@/utils';
import { hexToUint8Array, idlFactory, toFullDecimal, toHoleBigInt } from '../utils/icp';
import { Actor, HttpAgent } from '@dfinity/agent';
import { AuthClient } from '@dfinity/auth-client';
import { Principal } from '@dfinity/principal';
import { AccountIdentifier, LedgerCanister } from '@dfinity/ledger-icp';
import { IcrcLedgerCanister } from '@dfinity/ledger-icrc'
import { mapMutations, mapState } from 'vuex';
import vueQr from 'vue-qr'

export default {
  components: {
    CopyItem,
    vueQr
  },
  data() {
    return {
      receiveDialog: false,
      payDialog: false,
      payLoading: false,
      isLoading: false,
      principalId: null,
      accountId: null,
      actor: null,
      agent: null,
      intervalId: null,
      balance: 0,
      currentAsset: null,
      ownerCanister: 'uvxwt-pyaaa-aaaak-qis7a-cai',
      assets: {
        icp: {
          label: 'ICP',
          address: 'ryjl3-tyaaa-aaaaa-aaaba-cai',
          decimal: '8',
          shortDecimal: '8',
          transactionFee: '10000'
        },
        ckbtc: {
          label: 'ckBTC',
          address: 'mxzaz-hqaaa-aaaar-qaada-cai',
          decimal: '8',
          shortDecimal: '8',
          transactionFee: '10'
        },
        cketh: {
          label: 'ckETH',
          address: 'ss2fx-dyaaa-aaaar-qacoq-cai',
          decimal: '18',
          shortDecimal: '18',
          transactionFee: '2000000000000'
        },
        ckusdc: {
          label: 'ckUSDC',
          address: 'xevnm-gaaaa-aaaar-qafnq-cai',
          decimal: '6',
          shortDecimal: '6',
          transactionFee: '10000'
        }
      }
    }
  },
  computed: {
    ...mapState({
      payType: (state) => state.payType,
      qrText(state) {
        return state.orderData.invoice && state.orderData.invoice.qrcode || state.orderData.invoice.address || ''
      },
      remainTime: (state) => state.remainTime,
      payTypeList: (state) => state.payTypeList,
      addressText: (state) => {
        return state.orderData.invoice && state.orderData.invoice.address || state.orderData.invoice.qrcode || ''
      },
      payAmount: (state) => {
        return state.orderData.invoice && state.orderData.invoice.pay_amount || ''
      },
      order: (state) => {
        return state.orderData.order
      },
    }),
    transaction() {
      const payAmount = this.payAmount;
      const orderAmount = this.order.pay_amount;
      const assetDecimal = Number(this.currentAsset?.decimal || 0);
      const fee = toFullDecimal((this.currentAsset?.transactionFee || 0), assetDecimal, Number(this.currentAsset?.shortDecimal || 0))

      let total = toHoleBigInt(String((Number(this.payAmount) + Number(fee)).toFixed(assetDecimal)), assetDecimal);
      total = toFullDecimal(total, assetDecimal, Number(this.currentAsset?.shortDecimal || 0))

      const insufficientBalance = this.balance < total;

      return { orderAmount, payAmount, fee, total, insufficientBalance }
    },
    briefAddr() {
      let wallet = this.principalId
      // return wallet
      return wallet && `${wallet.substr(0, 6)}***${wallet.substr(wallet.length - 4, 4)}` || ''
    },
    networks() {
      const token = this.payTypeList.find((pay) => pay.key === 'ICP')
      return token.networks || []
    },
    currentMethod() {
      const networks = this.networks;
      return networks.find((pay) => pay.key === this.payType)
    }
  },
  methods: {
    ...mapMutations([
      'showStatus',
      'setOrder',
      'addLoading',
      'removeLoading'
    ]),
    async handleAuthenticated(authClient) {
      this.isLoading = true;
      try {
        this.currentAsset = this.assets[this.payType.toLowerCase()];

        if (this.currentAsset) {
          const identity = await authClient.getIdentity()
          const principal = identity.getPrincipal();

          // Principal ID
          this.principalId = Principal.from(principal).toText()

          // Generate Account ID
          this.accountId = AccountIdentifier.fromPrincipal({
            principal: principal
          }).toHex()

          // Generate Agent
          this.agent = new HttpAgent({ identity, host: 'https://ic0.app' });

          // Get balance for first time
          this.intervalCheckBalance();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.$message({
          message: error.message,
          type: 'error'
        })
      }
    },
    async connectWallet() {
      try {
        const authClient = await AuthClient.create();
        authClient.login({
          identityProvider: 'https://identity.ic0.app',
          maxTimeToLive: BigInt(7 * 24 * 60 * 60 * 1000 * 1000 * 1000), // 7 days in nanoseconds
          onSuccess: async () => this.handleAuthenticated(authClient)
        })
      } catch (error) {
        this.$message({
          message: error.message,
          type: 'error'
        })
      }
    },
    async getBalance() {
      try {
        if (this.currentAsset) {
          const { balance } = IcrcLedgerCanister.create({
            agent: this.agent,
            canisterId: Principal.fromText(this.currentAsset.address)
          })

          const myBalance = await balance({
            owner: Principal.fromText(this.principalId),
            subaccount: new Uint8Array(hexToUint8Array('0x0')),
            certified: false
          })

          this.balance = toFullDecimal(myBalance.toString(), Number(this.currentAsset.decimal), Number(this.currentAsset.shortDecimal));

          this.isLoading = false;
        } else {
          this.balance = 0;
        }
      } catch (error) {
        this.$message({
          message: error.message,
          type: 'error'
        })
      }
    },
    intervalCheckBalance() {
      if (this.payType && this.principalId) {
        this.balance = 0;
        this.getBalance();
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            // Your interval logic here
            this.getBalance();
          }, 10000); // Check balance every 10s
        } else {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      } else {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    checkoutData(type) {
      let order = getParameterByName('id')
      return Api.checkoutOrder({
        pay_currency: type
      }, order).then((res) => {
        if (res.data) {
          this.removeLoading()
          this.setOrder(res.data)
        }
      })
    },
    async disconnect() {
      const authClient = await AuthClient.create();
      if (await authClient.isAuthenticated()) {
        authClient.logout().then(() => {
          this.principalId = null;
          this.accountId = null;
          this.actor = null;
          this.agent = null;
          clearInterval(this.intervalId);
        })
      }
    },
    onCopy() {
      this.$message({
        message: 'Text Copied!',
        type: 'success'
      });
    },
    changeNetwork(type) {
      if (this.payType !== type) {
        this.addLoading()
        this.checkoutData(type)
      }
    },
    shortAddr(data) {
      return data.slice(0, 6) + '...' + data.slice(data.length - 4)
    },
    async swapPayment(total, recepient) {
      try {
        // Create Actor
        const owner = Principal.fromText(this.ownerCanister)
        const actor = Actor.createActor(idlFactory, {
          agent: this.agent,
          canisterId: owner
        })

        // 1. Approve
        const assetCanister = Principal.fromText(this.currentAsset.address);
        const decimal = Number(this.currentAsset.decimal || 0);
        const approvalPayload = {
          amount: Math.pow(100, decimal), // allow 100^(Max Decimal)
          spender: {
            owner,
            subaccount: []
          }
        }

        if (this.payType === 'ICP') {
          const { icrc2Approve } = LedgerCanister.create({
            agent: this.agent,
            canisterId: assetCanister
          })

          const res = await icrc2Approve(approvalPayload)
          console.log({ approve: res })
        } else {
          const { approve } = IcrcLedgerCanister.create({
            agent: this.agent,
            canisterId: assetCanister
          })

          const res = await approve(approvalPayload)
          console.log({ approve: res })
        }

        // 2. Call Pay Function
        const orderPayload = {
          token: assetCanister,
          amount: total,
          to_merchant: Principal.fromText(recepient),
          memo: this.order.salt,
        };
        const pay = await actor.pay(orderPayload)

        console.log({
          payload: {
            token: this.currentAsset.address,
            amount: total,
            to_merchant: recepient,
            memo: this.order.salt,
          }, response: pay
        });

        this.payLoading = false;
        this.payDialog = false;
        this.showStatus(true);

        // Call the balance after payment
        this.intervalCheckBalance();
      } catch (error) {
        console.log(error)
        this.payLoading = false
        this.$message({
          message: error.message,
          type: 'error'
        })
      }
    },
    async directPayment(total, recipient) {
      try {
        const { transfer } = IcrcLedgerCanister.create({
          agent: this.agent,
          canisterId: Principal.fromText(this.currentAsset.address),
        })

        const pay = await transfer({
          to: {
            owner: Principal.fromText(recipient),
            subaccount: []
          },
          amount: total
        })

        console.log(pay)

        this.payLoading = false;
        this.payDialog = false;
        this.showStatus(true);

        // Call the balance after payment
        this.intervalCheckBalance();
      } catch (error) {
        console.log(error)
        this.payLoading = false
        this.$message({
          message: error.message,
          type: 'error'
        })
      }
    },
    async doPayment() {
      this.payLoading = true
      // Clear interval during payment process
      clearInterval(this.intervalId);

      const total = toHoleBigInt(String(this.transaction.total), Number(this.currentAsset?.decimal || 0))
      const recipient = this.addressText;

      if (this.payType === 'CKUSDC') {
        this.directPayment(total, recipient)
      } else {
        this.swapPayment(total, recipient)
      }
    }
  },
  async created() {
    const authClient = await AuthClient.create();

    if (await authClient.isAuthenticated()) {
      this.handleAuthenticated(authClient)
    }
  },
  watch: {
    payType(newVal) {
      this.currentAsset = this.assets[newVal.toLowerCase()];
      this.intervalCheckBalance();
    }
  }
}
</script>
<style lang="scss">
.auto-wallet-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;

  .qrcode {
    width: 200px;
    height: 200px;
    margin: 0 auto -10px;
  }

  .pay-tips {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .remain-time {
      color: #55b5ff;
    }

    img {
      width: 18px;
      margin: 0;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin: 6px 0;

    > div {
      padding: 2px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      cursor: pointer;
      font-size: 13px;
      min-height: 24px;
      display: flex;
      align-items: center;
      min-width: 100px;
      justify-content: space-between;
      font-weight: 500;
      transition: all ease-in-out 0.3s;

      &.active {
        color: #55b5ff;
        border-color: #55b5ff;
      }

      &:hover {
        border-color: #55b5ff;
      }

      img {
        width: 18px;
        margin: 0;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .address-information {
    background-color: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-width: 300px;
    margin: 0 auto;
  }

  .wallet-information {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .address {
      cursor: pointer;
    }

    p {
      margin: 0;
      text-align: center;
    }

    .send-btn, .connect-btn {
      margin-top: 8px;
      width: 100%;
      padding: 0;
      height: 32px;
      line-height: 22px;
      color: #4e5c6e;

      &:hover, &:focus, &:active {
        border: 1px solid #55b5ff !important;
        color: #55b5ff;
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

    .balance {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;

      button {
        max-width: fit-content;
        color: #55b5ff;
        border: none;
        padding: 0;

        &:hover, &:focus, &:active {
          border: none !important;
          color: #2f80ed;
          text-decoration: underline;
        }
      }
    }
  }
}

.account-information {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .account-id {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .el-tag {
      width: fit-content;
      font-weight: bold;
    }
  }
}

.el-alert {
  .alert {
    margin: 0;
    line-height: 18px;
  }
}

.copy-act {
  color: #55b5ff;
  cursor: pointer;
  font-size: 16px;
}

.receive-dialog {
  .el-dialog__body {
    padding: 0 20px !important;
  }
}

.pay-dialog {
  .el-dialog__body {
    padding: 0 20px !important;

    .transaction-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      .section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0px;

        .label {
          text-align: left;
          font-weight: bold;
        }

        .value {
          text-align: right;
        }
      }
    }
  }

  .dialog-footer {
    p {
      margin-bottom: 5px;
    }

    button {
      width: 100%;
    }
  }
}

.btn-disconnect {
  color: #f56c6c;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  display: flex;
  margin: 8px auto 0;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }
}
</style>