import { Keypair, PublicKey } from '@solana/web3.js';
import { encodeURL } from '@solana/pay';
import BigNumber from 'bignumber.js';


function getData(address, pay_amount, title, description, order_id) {
  const recipient = new PublicKey(address);
  const amount = new BigNumber(pay_amount); // 0.1 SOL
  const reference = new Keypair().publicKey;

  return {
    recipient,
    amount,
    reference,
  }
}

export function generateUrl(
  address,
  pay_amount,
  title,
  description,
  order_id
) {

  const {
    recipient,
    amount,
    reference
  } = getData(address,
    pay_amount,
  )
  const url = encodeURL({ recipient, amount, reference, label: title, message: description, memo: order_id });
  return url;
}

export function generateUSDCUrl(
  address,
  pay_amount,
  title,
  description,
  order_id
) {
  const usdcsplToken = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
  const {
    recipient,
    amount,
    reference
  } = getData(address,
    pay_amount,
  )
  const url = encodeURL({ recipient, amount, splToken: usdcsplToken, reference, label: title, message: description, memo: order_id });
  return url;
}

export function generatePYUSDUrl(
  address,
  pay_amount,
  title,
  description,
  order_id
) {
  const usdcsplToken = new PublicKey('2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo');
  const {
    recipient,
    amount,
    reference
  } = getData(address,
    pay_amount,
  )
  const url = encodeURL({ recipient, amount, splToken: usdcsplToken, reference, label: title, message: description, memo: order_id });
  return url;
}

