<template>
<div>
    <div v-if = "wallet" class = "auto-wallet-container">
        <p>{{ `${$t('wallet.connected')}&nbsp;${briefAddr}` }}</p>
        <el-button plain @click = "submit()">{{ $t('send') }}</el-button>
    </div>
    <div v-else-if = "mobile"  class = "tabs-container">
        <p>
            {{$t('wallet.mobile') }}
        </p>
        &nbsp;
        <span  @click = "openWallet('OKX')">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA1UlEQVR4AWJwL/CBY0D7ZYCBQQhE4at0iU6RAB2pe0TnCKDsUTpFfw/YVoPo32dpeNDsvPmWStPDdqWu9ifB2ww9b80vsXC/LvSEGM0HCITBAkkWAIkIkADQuBISzrkWY2whhEHee8kIuef38IDXOgAKZ1FrFc2QmwS8lgFAvwsAXh8EOADY0TB8KufclFJNaz0Ia8hNauC1DiAJjYRATqrbB4C/FQK5DwMcAMIm3H8MSyn7juG5CQ8A/UFCe5IRxH+WWyKA4Y9m/OF0HM/NC+O5vff8ARHpaPq63KdGAAAAAElFTkSuQmCC" class = "type-icon" />
            OKX
        </span>
        <span  @click = "openWallet('TP')">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAMFBMVEVHcEwKgf82iP4pgP4pgP4pgP4pgP4sgv8ogP8pgP4pgP4ogP4ogP4pgP4qgf5Ej/+BjsvoAAAAD3RSTlMAAWP/wo73qQ97KEYe6diwe2k2AAAAiElEQVR4AWMYMMAoKCgogMRnMgYBBwbRZOMKsAAnWMCSKxlIbkAImAqDSCuEgE0xiLQFCfCEzjU2nnoAyAVqOgA2hc/YmIHb2PgCi7GxArJAAVDzBGSBT9vA1iAE/hsDaTQBU3SBBwKoAuYMKCqely4QQBEoADJxCLAA/SAFdhQCgDQLMgwgAADwMCmLSXpfsgAAAABJRU5ErkJggg==" class = "type-icon" />
            TP
        </span>


    </div>
</div>
</template>
<script>
    import { mapState } from 'vuex';
    import { ABI } from '../utils'
    export default {
        data() {
            return {
                mobile: null,
                wallet: null,
                tronWeb: null,
                TronWeb: null
            }
        },
        props: [
            'payAmount',
            'payAddress'
        ],
        computed: {
            briefAddr() {
                let wallet = this.wallet
                return wallet && `${wallet.substr(0, 6)}***${wallet.substr(wallet.length - 4, 4)}` || ''
            },
            ...mapState({
                payType: (state) => state.payType
            })
        },
        methods: {
            openWallet(wallet) {
                const dappUrl = window.location.href
                let deepLink

                if (wallet === 'OKX') {
                    const encodedDappUrl = encodeURIComponent(dappUrl);
                    deepLink = "okx://wallet/dapp/url?dappUrl=" + encodedDappUrl;
                } else if (wallet === 'TP') {
                    const urlObj = {
                        "url": dappUrl,
                        "chain": "TRX",
                        "source":"xxx"
                    }
                    deepLink = "tpdapp://open?params=" + encodeURIComponent(JSON.stringify(urlObj))
                } else if (wallet === 'TRONLINK') {
                    const urlObj = {
                        "url": dappUrl,
                        "action": "open",
                        "protocol": "tronlink",
                        "version": "1.0"
                    }
                    // https://docs-zh.tronlink.org/yi-dong-duan/deeplink/da-kai-qian-bao
                    deepLink = "tronlinkoutside://pull.activity?param=" + encodeURIComponent(JSON.stringify(urlObj))
                }
                location.href = deepLink
                return deepLink
            },
            isMobileDetection() {
                const ua = navigator.userAgent;
                const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
                const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
                const isMobile = isIOS || isAndroid;
                return isMobile;
            },
            checkAutoWallet() {
                let that = this

                const isMobile = that.isMobileDetection();
                if (isMobile) {
                    that.mobile = isMobile;
                }
                // Get the tron address
                if (window.tronWeb && window.tronWeb.defaultAddress) {
                    that.wallet = window.tronWeb.defaultAddress.base58
                    that.tronWeb = window.tronWeb
                }
            },
            async submit() {
                const wallet = this.wallet
                const payCurrency = this.payType
                const address = this.payAddress // todo
                const value = this.payAmount.toString() // todo
                console.log("Submitting ", wallet, payCurrency, address, value, this.payAmount)
                if (this.tronWeb) {
                    const tronWeb = this.tronWeb
                    if (payCurrency === 'P2P_USDT_TRC20') {
                        let trc20ContractAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';

                        const contract = await tronWeb.contract().at(trc20ContractAddress);
                        const amountUSDInt = parseInt(value * 1000000);
                        contract
                            .transfer(address, amountUSDInt)
                            .send()
                            .then(txHash => {

                            })
                            .catch(e => {
                              console.log(e);

                            });
                    }
                }
            }
        },
        created() {
            const TronWeb = require('tronweb');
            this.TronWeb = TronWeb
            this.checkAutoWallet()
        }
    }
</script>

<style lang="scss" scoped>
    .auto-wallet-container {
        p {
            text-align: center;
        }
        button {
            width: 100%;
            padding: 0px;
            height: 32px;
            line-height: 32px;
            color: #4e5c6e;
            &:hover, &:focus, &:active {
                border:1px solid #55b5ff!important;
                color: #55b5ff;
            }
        }
    }
    .tabs-container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        .type-icon {
            width: 18px;
            margin: 0 4px;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        span {
            padding: 0px 12px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            cursor: pointer;
            font-size: 12px;
            height: 24px;
            display: flex;
            align-items: center;
            & + span {
                margin-left: 10px;
            }
            &.active {
                color: #55b5ff;
                border-color: #55b5ff;
            }
        }

    }

</style>
