<template>
    <div class = "pay-method-item-container" @click = "toDetail()">
        <div class="sc-dTLGrV fxDdbH">
            <img class="sc-ivVeuv gSmhNx sc-cqCuEk fFSghU sc-ksYbfQ ijgyGm sc-cJSrbW dLwzxs" :src="(payTypeObj.icon || '')" alt="">
            <div class="sc-cmIlrE dCNcmI sc-ifAKCX cFlEyZ sc-bdVaJa iHZvIS">{{ $t(payTypeObj.name) }}</div>
        </div>
        <div class="dSFAD">
            <div class="sZSDS" v-if="(payTypeObj.networks || []).length > 0">
                <template v-for="(item) in payTypeObj.networks">
                    <span v-if="item.show" :key="item.key">
                        <el-tooltip class="item" effect="dark" :content="item.label" placement="bottom">
                            <img :src="item.icon" class = "type-icon" :alt="item.label"/>
                        </el-tooltip>
                    </span>
                </template>
            </div>
            <div class="sc-cCbXAZ dPQDID sc-CtfFt gznhvP sc-ifAKCX cFlEyZ sc-bdVaJa iHZvIS">
                {{ `${payTypeObj.describeTextPre || ''}${$t(payTypeObj.describeText)}` }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import Api from '../api'
    import { getParameterByName } from '../utils'
    export default {
        name: 'PayMethod',
        props: {
            payTypeObj: {
                default: () => {
                    return {
                    }
                },
                type: Object
            }
        },
        methods: {
            toDetail() {
              let key = this.payTypeObj.key;
              const showNet = (this.payTypeObj.networks || []).filter((net) => net['show'])

              if (showNet.length > 0) {
                key = showNet[0].key
              }

              this.addLoading()
              this.checkoutData(key)
            },
            checkoutData(type) {
                let order = getParameterByName('id')
                return Api.checkoutOrder({
                  pay_currency: type
                }, order).then((res) =>{
                    // 恢复数据定时查询
                    // this.$parent.$parent.$parent.addPoll()
                    if(res.data) {
                      this.removeLoading()
                      this.setOrder(res.data)
                      this.handleDetailPageStatus(1)
                    }
                })
            },
            /* initTimer() {
                clearInterval(this.$store.state.dataTimer)
            }, */
            ...mapMutations(['handleDetailPageStatus', 'setOrder', 'addLoading', 'removeLoading'])
        }
    }
</script>

<style lang="scss" scoped>
    .pay-method-item-container {
        display: flex;
        transition: all 0.2s linear 0s;
        margin: 8px auto;
        height: 65px;
        text-align: left;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 0 16px;
        gap: 8px;
        .fxDdbH {
            display: flex;
            font-size: 14px;
            color: #222222;
        }
        .ijgyGm {
            display: block;
            max-width: 100%;
            height: auto;
        }
        .fFSghU {
            width: 30px;
            height: 30px;
            transition: all 0.2s ease-in-out 0s;
            opacity: 1;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .dCNcmI {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.88;
            text-align: left;
            margin-left: 10px;
            color: rgb(32, 42, 54);
        }

        .dSFAD {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-end;

            .dPQDID {
                transition: margin 0.2s ease-in-out 0s;
                margin: 0px;
                font-weight: 500;
            }

            .sZSDS {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                span {
                    // padding: 2px 5px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 100%;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .type-icon {
                        width: 20px;
                        height: auto;
                        border-radius: 50%;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
        .gznhvP {
            // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAAAXNSR0IArs4c6QAAAUhJREFUSA2t1j1OwzAUB/D3TBbaFdi6wgSHKDtVh0owMJSBgQWJsvQQHbv0AnAHRJeKC5QjsHZtESXlkX+EI6W1sRP7LU7s+GfHXwnRX3SvH4+ubgcH+r5uqlCx039o/dB6tlql01BUXdzcn1C6eROhYyE5DUUVr2ksIi39iqGoajSSS2KeaxBpCMoAMG7Lz/SVRM5wr4OJ37MG20+T0ULnudIcxEOx0AKMhZbAGOgOGIoawRA03ykAtgMz29xPzk1LCivCtqOsPdQN2GYfDaHB7SXlBAFXQb3AKqg36ItaJwVAnfDuoe84eoG+GN7ICVbBnGBV7F+wDmYF62JGMATbAUOxEhgDK8BYWA7GxAAm2Z/Cc/YlLn0+bWcdKrhCKbV3x8wfxYOWg7Mod1zkWw8/S/S9eRGmL9Mp7DDMxb3+8BDjaS71z/0Fhu04ZnD4C9QAAAAASUVORK5CYII=);
            // background-position: center center;
            // background-repeat: no-repeat;
            // background-size: contain;
            // width: 20px;
            // height: 11px;
        }
    }
</style>
