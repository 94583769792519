<template>
  <div class="home" v-show="!loading">
    <div class="home-header">
      <div class="title-container">
        <img :src="appBrand.url || '/mediacryptos/mgwhite.png'" :alt="appBrand.text || 'MUGGLEPAY'">
        <div class="header-text">{{ appBrand.text || 'MUGGLEPAY' }}</div>
      </div>
      <!-- 多语言组件 -->
      <lang-list></lang-list>
    </div>
    <pending-loading v-if="(showStatus && pageStatus === 'NEW') || pageStatus === 'PENDING'"></pending-loading>
    <div class="home-others">
      <div class="home-body">
        <!-- Donation Section -->
        <template v-if="orderData.order?.pay_amount === 0 && orderData.order.pay_currency === 'USD'">
          <donation-form></donation-form>
        </template>
        <template v-else>
          <!-- General Invoice Section -->
          <template v-if="pageStatus === 'NEW' && !hasExpired && !showStatus">
            <div class="count-num-container">
              <div class="count-num" v-if="orderData.order">
                {{
                  new Intl.NumberFormat('en-US', {
                    style: 'currency', currency: orderData.order.pay_currency, currencyDisplay: 'code'
                  }).format(parseFloat(orderData.order.pay_amount || 0))
                }}
              </div>
            </div>
            <order-num></order-num>
          </template>
          <div class="home-body-main">
            <!-- 根据当前状态显示对应主界面 -->
            <status-expired v-if="pageStatus === 'EXPIRED' || (hasExpired && pageStatus === 'NEW')"></status-expired>
            <status-wrong v-else-if="pageStatus === 'WRONGPAID'"></status-wrong>
            <status-under v-else-if="pageStatus === 'UNDERPAID'"></status-under>
            <status-paid v-else-if="pageStatus === 'PAID'"></status-paid>
            <status-pending v-else-if="pageStatus === 'PENDING' || (showStatus && pageStatus === 'NEW')"></status-pending>
            <pay-list v-else-if="(pageStatus === 'NEW' && !showDetail)"></pay-list>
            <pay-detail v-else-if="(pageStatus === 'NEW' && showDetail)"></pay-detail>
          </div>
        </template>
      </div>
      <send-email v-if="pageStatus !== 'NEW' || hasExpired || showStatus"></send-email>
      <div class="home-footer">
        <p>{{ $t('Support') }}</p>
        <p class="icons">
          <!-- 底部help链接 -->
          <a href="javascript:void(0);">
            <img src="/mediacryptos/email.svg"
                 v-clipboard="() => appBrand.email || 'support@mugglepay.com'"
                 v-clipboard:success="onCopy" alt="Mugglepay"/>
          </a>

          <a href="https://t.me/+t_CPv22Ur95kMDVh">
            <img src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
                 alt=""
                 class="help"
                 style="width: 25px;"/>
          </a>
        </p>
        <p>
          {{ $t('ProcessedBy') }}
          <a target="_blank"
             :href="appBrand.website || 'https://mugglepay.com/'">
            <span style="color:#8ccdfe">{{
                appBrand.text || 'MugglePay'
              }}</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DonationForm from '../components/DonationForm';
import PayList from './PayList'
import PayDetail from './PayDetail'
import StatusPending from './StatusPending'
import StatusWrong from './StatusWrong'
import StatusUnder from './StatusUnder'
import StatusPaid from './StatusSuccess'
import StatusExpired from './StatusExpired'
import LangList from '../components/LangList'
import OrderNum from '../components/OrderNum'
import PendingLoading from '../components/PendingLoading'
import SendEmail from '../components/SendEmail'
import { mapState } from 'vuex'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    PayList,
    PayDetail,
    StatusPending,
    StatusWrong,
    StatusUnder,
    StatusPaid,
    StatusExpired,
    LangList,
    OrderNum,
    PendingLoading,
    SendEmail,
    DonationForm
  },
  data() {
    return {
      emailText: 'support@mugglepay.com'
    }
  },
  computed: {
    ...mapState({
      appBrand: (state) => state.appBrand,
      showDetail: (state) => state.showDetail,
      showStatus: (state) => state.showStatus,
      hasExpired: (state) => state.hasExpired,
      pageStatus: (state) => {
        let invoice = state.orderData.invoice
        if (!invoice) {
          return ''
        } else {
          // return 'PAID'
          if (invoice.status === 'PENDING') {
            // PENDING UNDERPAID WRONGPAID PAID
            return invoice.crypto_info && invoice.crypto_info.paidStatus || invoice.status
          } else {
            // NEW PAID EXPIRED
            return invoice.status
          }
        }
      },
      loading: (state) => state.loading,
      orderData: (state) => state.orderData
    }),
    emailLink() {
      return 'https://t.me/+t_CPv22Ur95kMDVh'
    }
  },
  methods: {
    onCopy() {
      this.$message({
        message: this.$t('emailCopied', { email: this.appBrand.email }),
        type: 'success'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  background-color: white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .home-header {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: #55b5ff;
    width: 100%;
    padding: 10px 10px;

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }

    img {
      display: inline-block;
      height: 100%;
      width: auto;
      margin-right: 8px;
      position: relative;
    }

    .header-text {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .home-others {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
    width: 100%;

    .home-body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .home-body-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        flex: 1;
      }

      .count-num-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        overflow: hidden;
        margin-top: 20px;

        .count-num-text {
          font-size: 12px;
          color: rgb(207, 214, 221);
          margin: 10px 0px;
        }

        .count-num {
          font-size: 34px;
          font-weight: 500;
          color: #222;
        }
      }
    }

    .home-footer {
      display: flex;
      align-items: center;
      text-align: center;
      height: 100px;
      width: 100%;
      flex-direction: column;

      a {
        text-decoration: none;
      }

      p {
        font-size: 14px;
        color: #575757;
        margin: 0;
        line-height: 1;
      }

      .icons {
        display: flex;
        align-items: center;
        margin: 10px 0;
        gap: 16px;

        img {
          width: 25px;
          height: auto;
          display: inline-block;

          &.help {
            width: 36px;
          }
        }
      }
    }
  }
}
</style>
