import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getParameterByName } from '../utils'

Vue.use(VueI18n);
let curLang = getParameterByName('lang') || localStorage.lang || 'en'
const i18n = new VueI18n({
  locale: curLang, // 当前使用语言
  messages: {
    'en': require('../assets/langs/en.json'),
    'ar': require('../assets/langs/ar.json'),
    'de': require('../assets/langs/de.json'),
    'es': require('../assets/langs/es.json'),
    'fr': require('../assets/langs/fr.json'),
    'id': require('../assets/langs/id.json'),
    'ja': require('../assets/langs/ja.json'),
    'ko': require('../assets/langs/ko.json'),
    'pt': require('../assets/langs/pt.json'),
    'vi': require('../assets/langs/vi.json'),
    'zh': require('../assets/langs/zh.json'),
  }
});
export default i18n