<template>
    <div class="sc-gVyKpa dFsEkJ sc-bGbJRg cMYLzl sc-ifAKCX cFlEyZ sc-bdVaJa iHZvIS">
      <div class="sc-bEjcJn kSQtWI sc-ifAKCX cFlEyZ sc-bdVaJa iHZvIS"></div>
      <div class="sc-ePZHVD fuWzKm sc-ifAKCX cFlEyZ sc-bdVaJa iHZvIS"></div>
      <div class="sc-likbZx dkmuCe sc-ifAKCX cFlEyZ sc-bdVaJa iHZvIS"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    // 动画
    .cMYLzl {
        width: 270px;
        height: 6px;
        border-radius: 2px;
        overflow-x: hidden;
    }
    .dFsEkJ {
        width: 100%;
        z-index: 1;
        position: relative;
    }
    .kSQtWI {
        position: absolute;
        border-radius: 2px;
        opacity: 0.1;
        background: rgb(0, 0, 0);
        width: 150%;
        height: 6px;
    }
    .fuWzKm {
        position: absolute;
        border-radius: 2px;
        background: #2f80ed;
        height: 6px;
        animation: 2s ease 0s infinite normal none running increase;
    }
    .dkmuCe {
        position: absolute;
        border-radius: 2px;
        background: #2f80ed;
        height: 6px;
        animation: 2s ease 0.5s infinite normal none running decrease;
    }
    @keyframes decrease {
      0% {
          left: -80%;
          width: 80%;
      }
      100% {
          left: 110%;
          width: 10%;
      }
    }
</style>