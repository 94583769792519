<template>
  <div class="pay-method-detail-container">
    <!-- ICP Network -->
    <icp-detail v-if="hasICPExtention"></icp-detail>
    <div v-else>
      <div class="common-type-container" v-if="isCommonType(payType)">
        <qrcode :payType="payType"
                :amount="accountText"
                :order_id="order_id"
                v-if="payType === 'SOL' || payType === 'USDC_SOL' || payType === 'PYUSD_SOL'"></qrcode>
        <vue-qr :logoSrc="imageUrl" :text="urlText" :size="200" v-else-if="payType != 'USDT'"></vue-qr>


        <!-- 麻瓜宝没有二维码 -->
        <div v-else class="usdt-box">
          <a href="https://wallet.mugglepay.com/" target="_blank">
            {{ `👉&nbsp;${$t('wallet.open')}&nbsp;` }}
            <img src="https://cdn.maguapay.com/pay/media/icons/telegramcolor.svg">
            &nbsp;
            <img src="https://cdn.maguapay.com/pay/media/icons/wechatcolor.svg">
          </a>
          <el-popover
              placement="top"
              width="80"
              :content="urlText + $t('COPYED')"
              trigger="manual"
              v-model="tipVisible"
              popper-class="copy-tips"
          >
            <p slot="reference" @click="tipVisible = !tipVisible" v-clipboard:copy="urlText">{{ urlText }}</p>
          </el-popover>
        </div>
        <p class="pay-time-tips">{{ $t('SendPaymentWithinPre') }} <span class="remainTime">{{ remainTime }}</span>
          {{ $t('SendPaymentWithinNext') }}</p>
        <!-- cusd 提示app下载地址 -->
        <div v-if="payType === 'CUSD'">
          <p class="usdt-download-tips-text">{{ $t('cusd.download') }}</p>
          <div class="usdt-download-tips">
            <a
                href="https://play.google.com/store/apps/details?id=co.clabs.valora"
                rel="noopener"
                target="_blank"
                className={styles.downloadBtn}
            >
              <svg
                  width="23"
                  height="25"
                  viewBox="0 0 23 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="css-lvyu5j"
              >
                <path
                    d="M15.74 16.53L1.8 24.45C1.48 24.63 1.19 24.7 0.940002 24.7L12.43 13.21L15.74 16.53Z"
                    fill="#0768AE"
                ></path>
                <path
                    d="M11.72 12.36L0.08 24C0.03 23.83 0 23.63 0 23.42V1.29997C0 1.08997 0.03 0.889971 0.08 0.719971L11.72 12.36Z"
                    fill="#0768AE"
                ></path>
                <path
                    d="M15.74 8.2L12.43 11.51L0.919983 0C1.17998 0 1.47998 0.09 1.79998 0.27L15.74 8.2Z"
                    fill="#0768AE"
                ></path>
                <path
                    d="M22.09 12.36C22.09 12.72 21.8 13.08 21.3 13.37L16.74 15.96L13.14 12.36L16.74 8.76001L21.3 11.35C21.8 11.64 22.09 12.01 22.09 12.36Z"
                    fill="#0768AE"
                ></path>
              </svg>
              Google Play
            </a>
            <a
                href="https://apps.apple.com/app/id1520414263"
                rel="noopener"
                target="_blank"
                className={styles.downloadBtn}
            >
              <svg
                  width="21"
                  height="25"
                  viewBox="0 0 21 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M20.3862 19.4829C20.0135 20.3598 19.5664 21.1613 19.0583 21.9012C18.3604 22.9082 17.7846 23.607 17.3442 23.9974C16.6599 24.6345 15.9282 24.9565 15.1423 24.9771C14.5799 24.9771 13.9024 24.8127 13.1098 24.4838C12.3171 24.155 11.5854 23.9974 10.9214 23.9974C10.2236 23.9974 9.47154 24.1619 8.67209 24.4838C7.86585 24.8127 7.22222 24.9839 6.72764 24.9976C5.97561 25.0319 5.22358 24.6962 4.47832 23.9906C3.99729 23.5659 3.40108 22.8465 2.6897 21.8258C1.92412 20.7297 1.28726 19.4692 0.792683 18.0238C0.264228 16.4618 0 14.9547 0 13.4887C0 11.8103 0.359078 10.3649 1.07724 9.15917C1.63957 8.18639 2.3916 7.41913 3.32656 6.85739C4.26829 6.29565 5.27778 6.00792 6.36856 5.98737C6.96477 5.98737 7.75068 6.17234 8.72629 6.54227C9.69512 6.91219 10.3184 7.09716 10.5962 7.09716C10.7995 7.09716 11.4905 6.87794 12.6694 6.43951C13.7805 6.03533 14.7154 5.87091 15.4878 5.93257C17.5677 6.10383 19.1328 6.93275 20.1694 8.42616C18.3062 9.56335 17.3916 11.1595 17.4051 13.2078C17.4187 14.804 17.9946 16.133 19.1192 17.188C19.6274 17.6744 20.1965 18.058 20.8333 18.3252C20.6843 18.7294 20.542 19.113 20.3862 19.4829ZM15.6165 0.500089C15.6165 1.75374 15.1626 2.91833 14.2615 4.00072C13.1775 5.28862 11.8631 6.02848 10.4336 5.91202C10.4133 5.7613 10.4065 5.60374 10.4065 5.43933C10.4065 4.24048 10.9214 2.95258 11.8428 1.90445C12.3035 1.37011 12.8862 0.931673 13.5908 0.575445C14.2954 0.226068 14.9661 0.0342527 15.5894 0C15.603 0.164413 15.6165 0.335676 15.6165 0.500089Z"
                    fill="#0768AE"
                ></path>
              </svg>
              App Store
            </a>
          </div>
        </div>

        <!-- usdt tab选项 -->
        <div v-if="(currentPayType?.networks || []).length > 0" class="usdt-tabs-container">
          <template v-for="(item) in currentPayType.networks">
                    <span v-if="item.show"
                          :class="{active: payType === item.key}"
                          @click="changeNetwork(item.key)"
                          :key="item.key">
                        {{ item.label }}
                        <img :src="item.icon" class="type-icon"/>
                    </span>
          </template>
        </div>
        <!-- 地址相关文案 -->
        <p class="pay-operation-tips" v-if="payType != 'USDT'">
          <span>{{ $t('makePaymentPre') }}</span>
          <img :src="currentPayMethod?.icon" class="type-icon"/>
          <span>{{ currentPayMethod?.currencyLabel }}&nbsp;</span>
          <span>{{ $t('makePaymentNext') }}</span>
        </p>
        <div class="sc-crNyjn hznKoK" v-if="payType != 'USDT' && showCopy">
          <copy-item :labelText="$t('Amount')"
                     :contentText="accountText + ' ' + currentPayMethod?.currencyLabel"
                     :copyText="accountText"></copy-item>
          <copy-item :labelText="currentPayMethod?.label + ` ${$t('Address')}`"
                     :contentText="handleAddress(addressText)"
                     :copyText="addressText && addressText.trim()"></copy-item>
          <copy-item :labelText="$t('Memo')"
                     :contentText="memo"
                     :copyText="memo"
                     v-if="hasMemo"></copy-item>
        </div>
        <!-- usdt 优惠码区域 -->
        <discount-code v-if="payType === 'USDT'"></discount-code>
      </div>
      <!-- metamask钱包唤起 -->
      <auto-pay-extention :payAmount="accountText" :payAddress="addressText" v-if="hasExtention"></auto-pay-extention>
      <!-- tron钱包唤起 -->
      <tron-extention :payAmount="accountText" :payAddress="addressText" v-if="hasTronExtention"></tron-extention>
    </div>

    <!-- 返回/下一步 -->
    <div class="operate-btn-container">
      <div class="back-btn btn" @click="back2List()">
        <i class="el-icon-arrow-left"></i>
        <span>{{ $t('Back') }}</span>
      </div>
      <div class="forward-btn btn" @click="toStatus()" v-if="isCommonType(payType)">
        <span>{{ $t('haveSent') }}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import CopyItem from '../components/CopyItem'
import Api from '../api'
import vueQr from 'vue-qr'
import { mapState, mapMutations } from 'vuex'
import DiscountCode from '../components/DiscountCode'
import AutoPayExtention from '../components/AutoPayExtention.vue'
import TronExtention from '../components/TronExtention.vue'
import IcpDetail from '../components/IcpDetail.vue'
import { getParameterByName } from '../utils'
import qrcode from '../components/qrcode.vue'

export default {
  components: {
    IcpDetail,
    vueQr,
    CopyItem,
    DiscountCode,
    AutoPayExtention,
    TronExtention,
    qrcode
  },
  data() {
    return {
      imageUrl: '',
      tipVisible: false,
      discountCode: '',
      activeTab: 'second',
      showCopy: true,
    }
  },
  mounted() {
    console.log(this.payType);
    console.log(this.accountText);
    console.log(this.order_id)
  },
  computed: {
    ...mapState({
      urlText: (state) => {
        return state.orderData.invoice && state.orderData.invoice.qrcode || state.orderData.invoice.address || ''
      },
      addressText: (state) => {
        return state.orderData.invoice && state.orderData.invoice.address || state.orderData.invoice.qrcode || ''
      },
      payType: (state) => {
        return state.orderData.invoice && state.orderData.invoice.pay_currency || ''
      },
      accountText: (state) => {
        return state.orderData.invoice && state.orderData.invoice.pay_amount || ''
      },
      remainTime: (state) => state.remainTime,
      payTypeList: (state) => state.payTypeList,
      order_id: (state) => state.orderData.invoice.order_id,
      memo: (state) => state.orderData.order.salt,
    }),
    hasExtention() {
      const usdt = ['P2P_USDT_ERC20', 'USDT_ARB', 'USDT_CELO', 'USDT_BNB'];
      const usdc = ['USDC', 'USDC_ARB', 'USDC_SOL', 'USDC_BASE', 'USDC_POL', 'USDCE_POL'];
      const ton = ['USDT_TON', 'TON', 'NOT'];
      const eth = ['P2P_ETH', 'ETH_ARB', 'ETH_BASE'];
      const custom = ['USDV_ARB', 'EURT', 'XAUT', 'SOL', 'CUSD_CELO', 'PYUSD_SOL'];

      return [...usdt, ...usdc, ...ton, ...eth, ...custom].includes(this.payType)
    },
    hasTronExtention() {
      return ['P2P_USDT_TRC20'].includes(this.payType)
    },
    hasICPExtention() {
      return ['ICP', 'CKBTC', 'CKETH', 'CKUSDC'].includes(this.payType)
    },
    hasMemo() {
      return ['USDT_TON', 'TON', 'NOT', 'USDC_SOL', 'SOL', 'PYUSD_SOL'].includes(this.payType)
    },
    currentPayType() {
      const obj = this.payTypeList.find(
          (obj) => obj.networks?.find((network) => network.key === this.payType)
      );

      if (typeof obj === 'undefined') {
        return this.payTypeList.find((pay) => pay.key === this.payType);
      }

      return obj
    },
    currentPayMethod() {
      let obj = null;
      this.payTypeList.forEach((payObj) => {
        if (payObj.hasOwnProperty('networks') && payObj.networks.length > 0) {
          const network = payObj.networks.find((network) => network.key === this.payType);

          if (typeof network !== 'undefined') {
            obj = network;
          }
        } else if (payObj.key === this.payType) {
          obj = payObj
        }
      });

      return obj;
    }
  },
  methods: {
    ...mapMutations([
      'showStatus',
      'handleDetailPageStatus',
      'setOrder',
      'addLoading',
      'removeLoading'
    ]),
    toStatus() {
      this.showStatus(1)
    },
    back2List() {
      this.handleDetailPageStatus(0)
    },
    handleAddress(data) {
      return data.slice(0, 6) + '...' + data.slice(data.length - 4)
    },
    changePayType2KeyLabel(data) {
      let active
      if (data) {
        active = this.payTypeList.filter((pay) => {
          if (data.includes('USDT')) {
            return pay.name === 'USDT'
          } else {
            return pay.key === data
          }
        })[0]
      } else {
        active = ''
      }
      return active && active['keyLabel'] || ''
    },
    changePayType2Img(data) {
      let active
      if (data) {
        active = this.payTypeList.filter((pay) => {
          if (data.includes('USDT')) {
            return pay.name === 'USDT'
          } else {
            return pay.key === data
          }
        })[0]
      } else {
        active = ''
      }
      return active && active['icon'] || ''
    },
    changeNetwork(type) {
      if (this.payType !== type) {
        this.addLoading()
        this.checkoutData(type)
      }
    },
    checkoutData(type) {
      let order = getParameterByName('id')
      return Api.checkoutOrder({
        pay_currency: type
      }, order).then((res) => {
        if (res.data) {
          this.removeLoading()
          this.setOrder(res.data)
        }
      })
    },
    isCommonType(type) {
      // 微信支付宝界面跟其他类型相差比较大，分开展示
      return true
    },
    handleTab(tab) {
      console.log(tab)
    }
  },
  watch: {
    tipVisible(val) {
      if (val) {
        setTimeout(() => {
          this.tipVisible = false
        }, 300);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.pay-method-detail-container {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  width: 100%;

  .type-icon {
    width: 18px;
    margin: 0 4px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .common-type-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .usdt-box {
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin-top: 20px;
      flex-direction: column;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 26px;
        line-height: 26px;
        color: #55b5ff;
        cursor: pointer;
      }
    }
  }

  a {
    text-align: center;
  }

  img {
    margin: 10px auto 0 auto
  }

  .pay-time-tips {
    margin-top: -10px;
    text-align: center;
    font-size: 14px;
    color: #575757;
  }

  .pay-operation-tips {
    text-align: center;
    font-size: 13px;
    color: #575757;
    display: flex;
    align-items: center;
    justify-content: center;

    .type-icon {
      width: 18px;
      margin: 0 4px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    span {
      white-space: nowrap;
    }
  }
}

.hznKoK {
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.idRzGk {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 50px 20px;
  height: 100%;
  min-height: 500px;
  width: 100%;
  background-color: white;
}

.operate-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .btn {
    //width: 140px;
    text-align: right;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.back-btn {
      text-align: left;
    }
  }

  .forward-btn {
    color: rgb(140, 205, 254);
  }
}

.remainTime {
  color: #55b5ff;
}

.usdt-tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  span {
    padding: 2px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    cursor: pointer;
    font-size: 13px;
    min-height: 24px;
    display: flex;
    align-items: center;
    min-width: 100px;
    justify-content: space-between;
    font-weight: 500;
    transition: all ease-in-out 0.3s;

    &.active {
      color: #55b5ff;
      border-color: #55b5ff;
    }

    &:hover {
      border-color: #55b5ff;
    }
  }
}

.cusd-tabs {
  margin-top: 10px;
}

.usdt-download-tips-text {
  text-align: center;
  color: #222;
  font-size: 14px;
}

.usdt-download-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222;
    text-decoration: none;
    cursor: pointer;

    &:focus, &:active {
      color: #222;
      outline: none;
    }

    svg {
      margin-right: 6px;
    }

    & + a {
      margin-left: 10px;
    }
  }
}
</style>
