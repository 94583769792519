<template>
    <div class="email-container">
        <p class = "tips-text" :class = "{cn: $i18n.locale == 'zh'}">
            {{ $t('EnterEmail') }}
            <div class = "input-container">
                <el-input v-model="emailText" :placeholder="$t('Email')"></el-input>
                <img src="/mediacryptos/email.svg" alt="" class = "email-icon" @click = "sendEmail()">
            </div>
        </p>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { getParameterByName } from '../utils'
    export default {
        data() {
            return {
                emailText: ''
            }
        },
        methods: {
            ...mapActions([
                'sendEmailData'
            ]),
            sendEmail() {
                // 校验邮箱
                let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                if(reg.test(this.emailText)) {
                    this.sendEmailData({emailText: this.emailText, order: getParameterByName('id')}).then(() => {
                        // 提示发送成功
                        this.$message({
                            message: this.$t('Success'),
                            type: 'success'
                        });
                    })
                } else {
                    // 邮箱错误提示
                    this.$message({
                        message: this.$t('emailFaild'),
                        type: 'error'
                    });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .email-container {
        position: relative;
        padding: 0 20px;
        bottom: 50px;
        .tips-text {
            margin-top: 26px;
            margin-bottom: 10px;
            text-align: justify;
            &.cn {
                text-align: center;
            }
        }
        .input-container {
            position: relative;
            .email-icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                position: absolute;
                right: 10px;
                top: 11px;
                cursor: pointer;
            }
        }
    }
    /deep/ .el-input__inner {
        &:focus {
            border-color: #55b5ff!important;
        }
    }
</style>