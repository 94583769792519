<template>
    <div class = "list-container">
        <!-- <a href="https://mugglepay.com" target = "_blank"><button class="sc-hGoxap euznBx sc-iwsKbI IprnD sc-dnqmqq kVWhsK" v-if = "isCn">{{ $t('mugglePay') }}</button></a> -->
        <p class="sc-dBaXSw fxYJMl">{{ $t(isCn ? 'OrSelectCrypto': 'SelectCrypto') }}</p>
        <!-- options -->
        <pay-method v-for = "(item, index) in payTypeList" :key="index" :payTypeObj = "item" v-show = "index < 5 || loadMore"></pay-method>
        <button v-if = "needMore" @click = "handleLoadMore()" class="sc-doWzTn gKKrzB sc-hGoxap hLHDWG sc-iwsKbI IprnD sc-dnqmqq kVWhsK">{{moreText}}</button>
    </div>
</template>

<script>
    import PayMethod from '../components/PayMethod'
import { mapState } from 'vuex'
    export default {
        components: {
            PayMethod
        },
        data() {
            return {
                loadMore: false
            }
        },
        methods: {
            handleLoadMore() {
                this.loadMore = !this.loadMore
            }
        },
        computed: {
            moreText() {
                return this.loadMore ? this.$t('ShowLess') : this.$t('ShowMore')
            },
            ...mapState({
                payTypeList: (state) => {
                    return state.payTypeList.filter((list) =>{
                        return list.show;
                    }).sort((a, b) => a.order - b.order)
                }
            }),
            needMore() {
                return this.payTypeList.length > 5 ? true : false
            },
            isCn() {
                return this.$i18n.locale == 'zh'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        a {
            width: 100%;
            text-decoration: none;
        }
        .IprnD {
            font-family: inherit;
            font-weight: 700;
            line-height: 1.14286;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            text-decoration: none;
            border-radius: 4px;
            border: 0px;
            appearance: none;
            background-color: rgb(125, 186, 229);
        }
        .kVWhsK {
            margin: 0px;
            padding: 8px 16px;
            font-size: 14px;
            color: rgb(255, 255, 255);
            &:focus-visible {
                outline: none;
            }
        }
        .euznBx {
            visibility: visible;
            height: 50px;
            width: 100%;
            transition: all 0.2s ease-in-out 0s;
            display: inline-flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            font-weight: 600;
            font-size: 15px;
            background-color: #55b5ff;
            color: rgb(255, 255, 255);
        }
        .fxYJMl {
            color: #575757;
            font-size: 12px;
            line-height: 16px;
            text-align: left;
            width: 100%;
        }
        .hLHDWG {
            visibility: visible;
            height: 40px;
            width: auto;
            transition: all 0.2s ease-in-out 0s;
            display: inline-flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            font-weight: 600;
            background-color: rgb(255, 255, 255);
            color: #55b5ff;
        }
    }
</style>