<template><div></div></template>
<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";

Vue.use(VueConfetti);

export default {
  mounted() {
    this.startConfetti();

    setTimeout(() => {
      this.stopConfetti();
    }, 2500);
  },
  methods: {
    startConfetti() {
      this.$confetti.start({
        particles: [
          {
            type: "image",
            url: "/mediacryptos/btc.png",
          },
          {
            type: "image",
            url: "/mediacryptos/bnb.png",
          },
          {
            type: "image",
            url: "/mediacryptos/bch.png",
          },
          {
            type: "image",
            url: "/mediacryptos/ltc.png",
          },
          {
            type: "image",
            url: "/mediacryptos/cusd.png",
          },
          {
            type: "image",
            url: "/mediacryptos/eth.png",
          },
          {
            type: "image",
            url: "/mediacryptos/trx.png",
          },
          {
            type: "image",
            url: "/mediacryptos/usdc.png",
          },
          {
            type: "image",
            url: "/mediacryptos/usdt.png",
          },
          {
            type: "image",
            url: "/mediacryptos/usdv.png",
          },
        ],
      });
    },
    stopConfetti() {
      this.$confetti.stop();
    },
  },
};
</script>
