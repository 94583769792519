import Axios from 'axios'
// import Config from '../config.json';

// const API_TOKEN = Config.MUGGLEPAY_API_TOKEN;
// const API_HOST = Config.MUGGLE_BACKEND;
const API_TOKEN = process.env.VUE_APP_MUGGLEPAY_API_TOKEN;
const API_HOST = process.env.VUE_APP_MUGGLE_BACKEND;

Axios.interceptors.request.use(function (config) {
    let token = API_TOKEN;
    if (token) {
        config.headers.token = token;     
        return config;
    }
}, function (error) {
    return Promise.reject(error);
});
export default {
    getAppBrand(domain) {
        let url = `${API_HOST}/brand?domain=${domain}`
        return Axios.get(url)
    },
    getOrderDetail(order) {
        let url = `${API_HOST}/v1/orders/` + order
        return Axios.get(url)
    },
    checkoutOrder(payload, order) {
        let url = `${API_HOST}/v1/orders/${order}/checkout`
        return Axios.post(url, payload)
    },
    sendEmail(email, order) {
        let url = `${API_HOST}/v1/orders/${order}/meta`
        return Axios.post(url, {
            email: email
        })
    },
    verifyCoupon(coupon, order) {
        let url = `${API_HOST}/v1/orders/${order}/coupon`
        return Axios.post(url, {
            coupon
        })
    }
}
