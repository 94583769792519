<template>
  <div>
    <order-num class="donation-number"></order-num>
    <el-form :model="form" label-position="top" ref="form" :rules="rules" class="donation-form">
      <el-form-item :label="$t('donation.amount')" prop="amount" class="amount-field">
        <el-input
            type="number"
            v-model="form.amount"
            :placeholder="$t('donation.placeholder')"
            :min="min"
            :step="min"
        >
          <template slot="prepend">USD</template>
        </el-input>
      </el-form-item>
      <el-form-item class="btn-act">
        <el-button type="primary"
                   @click="submitDonation"
                   size="medium"
                   :loading="isLoading"
                   :disabled="Number(this.form.amount || 0) < min">
          {{ $t('donation.btn') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Api from '@/api';
import OrderNum from '@/components/OrderNum.vue';
import { getParameterByName } from '@/utils';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: { OrderNum },
  data() {
    return {
      isLoading: false,
      min: 0.01,
      form: {
        amount: 0,
      },
      rules: {
        amount: [
          { required: true, message: 'Please enter a donation amount', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value < this.min) {
                callback('Donation amount must be at least 0.01');
              } else {
                callback();
              }
            },
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!/^\d+(\.\d+)?$/.test(value)) {
                callback('Donation amount must be a valid number');
              } else {
                callback();
              }
            },
            trigger: 'blur'
          },
        ],
      }
    }
  },
  methods: {
    ...mapMutations(['setOrder']),
    ...mapActions(['getOrderData']),
    submitDonation() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let orderId = getParameterByName('id')

          this.isLoading = true
          Api.checkoutOrder({
            price_amount: this.form.amount
          }, orderId).then((res) => {
            if (res.data) {
              if (res.data.hasOwnProperty('error')) {
                this.$message({
                  message: res.data.error,
                  type: 'error'
                })
              } else {
                // Refresh Order Data
                this.getOrderData(orderId).then((res) => {
                  if (res.data) {
                    this.setOrder(res.data)
                    this.isLoading = false
                  }
                })
              }
            }
          })
        }
      });
    },
  },
}
</script>
<style lang="scss">
.donation-form {
  width: 100%;
  max-width: 320px;

  label {
    line-height: 20px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  .amount-field {
    margin-bottom: 16px;
  }

  .btn-act {
    width: 100%;
    margin-bottom: 0;

    button {
      width: 100%;
    }
  }
}

.donation-number {
  min-height: unset !important;
  margin-bottom: 30px !important;

  > p {
    font-size: 20px !important;
    font-weight: bold !important;
  }
}
</style>