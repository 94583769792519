<template>
  <div style="display: flex; align-items: center; justify-content: center;">
    <div ref="qrRef" />
  </div>
</template>

<script>
import { createQROptions } from "@solana/pay";
import QRCodeStyling from "@solana/qr-code-styling";
import { generateUrl, generateUSDCUrl, generatePYUSDUrl } from '../utils/payqrcode'
import { mapState, mapMutations } from 'vuex'

export default {
  // 根据prop传递的是什么类型，这里就定义成什么类型
  props: {
    payType: {
      type: String,
      default: "SOL"
    },
    order_id: {
      type: String,
    },
    // amount: {
    //   type: Number,
    // }
  },
  data() {
    return {
      size: 200,
      url:'',
      options: {},
      qr: null,
    };
  },
  computed: {
    ...mapState({
      // order_id:(state) => state.orderData.invoice.order_id,
      amount:(state) => state.orderData.invoice.pay_amount,
      title: (state) => state.orderData.order.title,
      description: (state) => state.orderData.order.description,
      address: (state) => state.orderData.invoice.address,
    }),
  },
  mounted() {

    const baseSize = 200
    this.url = null;

    if (this.payType === 'SOL') {
      this.url = generateUrl(
        this.address,
        this.amount,
        this.title,
        this.description,
        this.order_id
      )
    } else if (this.payType === 'USDC_SOL') {
      this.url = generateUSDCUrl(
        this.address,
        this.amount,
        this.title,
        this.description,
        this.order_id

      )
    } else if (this.payType === 'PYUSD_SOL') {
      this.url = generatePYUSDUrl(
        this.address,
        this.amount,
        this.title,
        this.description,
        this.order_id
      )
    }

    this.size = typeof window === "undefined"
      ? baseSize
      : Math.min(window.screen.availWidth - 48, baseSize);

    const listener = () => {
      this.size = Math.min(window.screen.availWidth - 48, baseSize);
    };
    window.addEventListener("resize", listener);

    this.options = createQROptions(this.url, this.size, "transparent", "#2a2a2a");

    this.qr = new QRCodeStyling();
    this.qr.update(this.options);

    this.$nextTick(() => {
      if (this.$refs.qrRef) {
        this.qr.append(this.$refs.qrRef);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
};
</script>
<style scoped>
/* 在这里添加你的 QRCode.module.css 内容 */
</style>
