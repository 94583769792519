<template>
    <div class = "coupon-container">
        <div class = "coupon-describe-box" v-if = "couponSuccess">
            <p>{{ `${$t('price.label')}&nbsp;${receive_amount} ${changePayType2KeyLabel(payType)}` }}</p>
            <p>{{ `${$t('price.coupon')}&nbsp;$${couponData.discount_amount}(${$t('price.discount')}${amountPercent}%)` }}</p>
            <p>{{ `${$t('price.pay')}&nbsp;${couponData.sender_amount} ${changePayType2KeyLabel(payType)}` }}</p>

        </div>
        <el-input placeholder="" v-model="discountCode" class = "discountCode-input" v-if = "payType == 'USDT'">
            <template slot="prepend">
                <span>{{ $t('coupon.text') }}</span>
            </template>
            <template slot="append"><span @click = "verify()">{{ $t('verify') }}</span></template>
        </el-input>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { getParameterByName } from '../utils'
    export default {
        data() {
            return {
                discountCode: '',
                couponData: {},
                couponSuccess: 0
            }
        },
        computed: {
            ...mapState({
                payType: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.pay_currency || ''
                },
                payTypeList: (state) => state.payTypeList,
                receive_amount: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.receive_amount || ''
                }
            }),
            amountPercent() {
                return (((this.couponData.discount_amount || 0) * 100) / this.receive_amount).toFixed(2)
            }
        },
        methods: {
            ...mapActions([
                'verifyCoupon'
            ]),
            verify() {
                let order = getParameterByName('id')
                let coupon = this.discountCode
                this.verifyCoupon({
                    coupon,
                    order
                }).then((res) => {
                    this.handleCouponResult(res.data)
                })
            },
            handleCouponResult(data) {
                let h = this.$createElement;
                if (data.invoice) {
                    this.couponData = data.coupon
                    if (!data.coupon || !data.coupon.sender_amount) {
                        const error = data.coupon && data.coupon.error;
                        const minUSD = data.coupon && data.coupon.min_usdt;
                        const maxUSD = data.coupon && data.coupon.max_usdt;

                        let description = this.$t('coupon.unknown');
                        if (error === 'NOT_EXIST') {
                            description = this.$t('coupon.none');
                        } else if (error === 'MIN_LIMIT') {
                            description = this.$t('coupon.min');
                            description = `${description} $${minUSD}`;
                        } else if (error === 'MAX_LIMIT') {
                            description = this.$t('coupon.max');
                            description = `${description} $${maxUSD}`;
                        } else if (error === 'EXPIRED') {
                            description = this.$t('coupon.expired');
                        }
                        this.$notify({
                            title: this.$t('notification.error'),
                            message: h('i', { style: ''}, description)
                        });
                    } else {
                        // 优惠券识别成功
                        this.couponSuccess = 1
                    }
                }
            },
            changePayType2KeyLabel(data) {
                let active
                if(data) {
                    active = this.payTypeList.filter((pay) => {
                        if(data.includes('USDT')) {
                            return pay.name == 'USDT'
                        } else {
                            return pay.key == data
                        }
                    })[0]
                } else {
                    active = ''
                }
                return active && active['keyLabel'] || ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    .coupon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .coupon-describe-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            color: #222222;
            p {
                margin: 0px;
                line-height: 1.5;
            }
        }
        .discountCode-input {
            margin-top: 30px;
            margin-bottom: 10px;
            /deep/ .el-input-group__append {
                color: #55b5ff;
                background: #fafafa;
                cursor: pointer;
            }
            /deep/ .el-input-group__prepend {
                color: #222222;
                background: #fafafa;
            }
            /deep/ input {
                &:focus {
                    border-color: #55b5ff;
                }
            }
        }
    }
</style>