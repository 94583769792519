<template>
    <div class = "status-container">
        <div class = "top-container">
            <img src="/mediacryptos/info.svg" alt="">
            <p class = "status-text">{{ $t('Expired') }}</p>
            <p class = "status-tips-text">{{ $t('ExpiredText') }}</p>
            <!-- <a href="">
                <a href=""><p>{{ $t('CreateOrder') }}</p></a>
            </a> -->
        </div>
        <back></back>
    </div>
</template>

<script>
    import Back from '../components/Back'
    import OrderNum from '../components/OrderNum'
    export default {
        components: {
            OrderNum,
            Back
        }
    }
</script>

<style lang="scss" scoped>
    .status-container {
        .top-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                text-align: center;
            }
            img {
                display: inline-block;
                width: 80px;
                height: 100px;
            }
            .status-text {
                font-weight: bold;
            }
        }
        a {
            text-decoration: none;
            color: #55b5ff;
        }
    }
</style>