<template>
  <div class="status-container">
    <div class="top-container">
      <img src="/mediacryptos/check.svg?t=1" alt="" />
      <p class="status-text">{{ $t("Success") }}</p>
      <p class="status-tips-text">{{ $t("SuccessText") }}</p>
    </div>
    <order-num></order-num>
    <back></back>
    <fire-work></fire-work>
  </div>
</template>

<script>
import OrderNum from "../components/OrderNum";
import Back from "../components/Back";
import FireWork from "../components/FireWork";
export default {
  components: {
    OrderNum,
    Back,
    FireWork,
  },
};
</script>

<style lang="scss" scoped>
.status-container {
  width: 100%;
  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
    img {
      width: 80px;
    }
    .status-text {
      font-weight: bold;
    }
  }
}
</style>
