<template>
  <div class="order-num">
    <p class="sc-dphlzf CdROr">{{
        `${$t('OrderID')}: ${(orderData.order && orderData.order.order_id && splitOrder(orderData.order.order_id) || '')}`
      }}</p>
    <p class="sc-dphlzf CdROr" v-if="orderData.order && orderData.order.title">
      {{orderData.order.title }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      orderData: (state) => state.orderData
    }),
    order_id() {
      let order_id = this.orderData.invoice && this.orderData.invoice.address || this.orderData.invoice.order_id || ''
      return this.splitOrder(order_id)
    }
  },
  methods: {
    splitOrder(val) {
      let prefix = val.slice(0, 8)
      return prefix.toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.order-num {
  //margin: 15px 0px;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  color: #222;
  margin-top: 0;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ddd;

  .CdROr {
    text-align: center;
    line-height: 20px;
    overflow: hidden;
    margin: 0;
    font-size: 14px;
    color: #222;
    font-weight: 400;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}
</style>