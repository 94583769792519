<template>
  <div class="langs-container">
    <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link">
          {{ targetLangName }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :key="lang.key"
                          v-for="(lang) in langs"
                          :command="lang.key"
                          :class="{'dropdown-item-active': lang.key === targetLangKey}">
          {{ lang.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { getParameterByName } from '../utils'

export default {
  data() {
    return {
      langs: [
        {
          label: 'English (English)',
          key: 'en'
        },
        {
          label: 'Chinese (中文)',
          key: 'zh'
        },
        {
          label: 'Indonesian (Bahasa Indonesia)',
          key: 'id'
        },
        {
          label: 'Korean (한국어)',
          key: 'ko'
        },
        {
          label: 'Vietnamese (Tiếng Việt)',
          key: 'vi'
        },
        {
          label: 'Japanese (日本語)',
          key: 'ja'
        },
        {
          label: 'Spanish (Español)',
          key: 'es'
        },
        {
          label: 'Portuguese (Português)',
          key: 'pt'
        },
        {
          label: 'French (Français)',
          key: 'fr'
        },
        {
          label: 'German (Deutsch)',
          key: 'de'
        },
        {
          label: 'Arabic (العربية)',
          key: 'ar'
        }
      ],
      targetLangName: 'English (English)',
      targetLangKey: 'en',
    }
  },
  methods: {
    handleCommand(command) {
      this.$i18n.locale = command
      localStorage.lang = command

      const selected = this.langs.find((lg) => lg.key === command)
      this.targetLangKey = selected.key
      this.targetLangName = selected.label
    }
  },
  created() {
    const param = getParameterByName('lang') || localStorage.lang || 'en'
    const selected = this.langs.find((lg) => lg.key === param)
    this.targetLangKey = selected.key
    this.targetLangName = selected.label
  },
  /* beforeCreate() {
   !function handleHistoryData() {
   console.log(localStorage)
   if(localStorage.lang && localStorage.lang == 'chs') {
   localStorage.lang = 'zh'
   } else if(localStorage.lang && localStorage.lang == 'xby') {
   localStorage.lang = 'es'
   }
   }.bind(this)()
   } */
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.dropdown-item-active {
  background-color: #ecf5ff;
  color: #66b1ff;
}
</style>