import bigInt from 'big-integer'

export const idlFactory = ({ IDL }) => {
  const AddOwnerArgs = IDL.Record({ 'owner': IDL.Principal });
  const Error = IDL.Variant({
    'OwnerAlreadyExists': IDL.Null,
    'InsufficientAllowance': IDL.Record({ 'allowance': IDL.Nat }),
    'SwapAlreadyExists': IDL.Null,
    'InsufficientBalance': IDL.Record({ 'balance': IDL.Nat }),
    'InvalidPrincipal': IDL.Null,
    'IcCdkError': IDL.Record({ 'message': IDL.Text }),
    'OwnerNotFound': IDL.Null,
    'SwapNotFound': IDL.Null,
    'SwapTokenNotFound': IDL.Null,
    'Forbidden': IDL.Null,
    'AmountTooSmall': IDL.Null,
  });
  const Result = IDL.Variant({ 'Ok': IDL.Null, 'Err': Error });
  const AddSwapArgs = IDL.Record({
    'token0': IDL.Principal,
    'token1': IDL.Principal,
    'pool_canister': IDL.Principal,
  });
  const GetErrorLogsArgs = IDL.Record({
    'start': IDL.Opt(IDL.Nat64),
    'length': IDL.Opt(IDL.Nat64),
  });
  const LogLevel = IDL.Variant({
    'Error': IDL.Null,
    'Info': IDL.Null,
    'Debug': IDL.Null,
  });
  const Log = IDL.Record({
    'level': LogLevel,
    'message': IDL.Text,
    'timestamp': IDL.Nat64,
  });
  const GetMerchantBalanceArgs = IDL.Record({
    'token': IDL.Principal,
    'merchant': IDL.Principal,
  });
  const GetMerchantBalancesArgs = IDL.Record({ 'merchant': IDL.Principal });
  const MerchantWithdrawArgs = IDL.Record({
    'to': IDL.Opt(IDL.Principal),
    'token': IDL.Principal,
    'amount': IDL.Nat,
  });
  const PayArgs = IDL.Record({
    'token' : IDL.Principal,
    'memo' : IDL.Nat64,
    'to_merchant' : IDL.Principal,
    'amount' : IDL.Nat,
  });
  const RemoveSwapArgs = IDL.Record({
    'token0': IDL.Principal,
    'token1': IDL.Principal,
  });
  const WithdrawBalanceArgs = IDL.Record({
    'to': IDL.Principal,
    'token': IDL.Principal,
    'amount': IDL.Nat,
  });
  return IDL.Service({
    'add_owner': IDL.Func([AddOwnerArgs], [Result], []),
    'add_swap': IDL.Func([AddSwapArgs], [Result], []),
    'get_error_logs': IDL.Func([GetErrorLogsArgs], [IDL.Vec(Log)], ['query']),
    'get_merchant_balance': IDL.Func(
      [GetMerchantBalanceArgs],
      [IDL.Nat],
      ['query'],
    ),
    'get_merchant_balances': IDL.Func(
      [GetMerchantBalancesArgs],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
      ['query'],
    ),
    'get_owners': IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'get_swaps': IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Principal))],
      ['query'],
    ),
    'merchant_withdraw': IDL.Func([MerchantWithdrawArgs], [Result], []),
    'pay': IDL.Func([PayArgs], [Result], []),
    'remove_owner': IDL.Func([AddOwnerArgs], [Result], []),
    'remove_swap': IDL.Func([RemoveSwapArgs], [Result], []),
    'withdraw_balance': IDL.Func([WithdrawBalanceArgs], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };
export const toFullDecimal = (
  numb,
  decimal,
  maxDecimals
) => {
  if (BigInt(numb) === BigInt(0)) {
    return '0'
  }

  let numbStr = numb.toString()
  if (decimal === numbStr.length) {
    if (maxDecimals === 0) {
      return '0'
    }
    const newNumber = numbStr
      .slice(0, maxDecimals || decimal)
      .replace(/0+$/, '')
    return '0.' + newNumber
  } else if (decimal > numbStr.length) {
    for (let index = 0; index < decimal; index++) {
      numbStr = '0' + numbStr
      if (numbStr.length > decimal) {
        break
      }
    }
  }
  const holeStr = numbStr
    .slice(0, numbStr.length - decimal)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (maxDecimals === 0) {
    return holeStr
  }

  const decimalStr = numbStr.slice(numbStr.length - decimal).replace(/0+$/, '')
  if (decimalStr === '') {
    return holeStr
  } else {
    const newNumber =
      holeStr + '.' + decimalStr.slice(0, maxDecimals || decimal)
    if (Number(newNumber) === 0) {
      return '0'
    } else {
      return holeStr + '.' + decimalStr.slice(0, maxDecimals || decimal)
    }
  }
}

export const hexToNumber = (hexFormat) => {
  if (hexFormat.slice(0, 2) !== '0x') {
    return undefined
  }
  const hex = hexFormat.substring(2)
  if (/^[a-fA-F0-9]+$/.test(hex)) {
    let numb = bigInt()
    for (let index = 0; index < hex.length; index++) {
      const digit = hex[hex.length - index - 1]
      numb = numb.add(
        bigInt(16)
          .pow(bigInt(index))
          .multiply(bigInt(`0x${digit}`))
      )
    }
    return numb
  } else {
    return undefined
  }
}
export const hexToUint8Array = (hex) => {
  const zero = bigInt(0)
  const n256 = bigInt(256)
  let bigNumber = hexToNumber(hex)
  if (bigNumber) {
    const result = new Uint8Array(32)
    let i = 0
    while (bigNumber.greater(zero)) {
      result[32 - i - 1] = bigNumber.mod(n256).toJSNumber()
      bigNumber = bigNumber.divide(n256)
      i += 1
    }
    return result
  } else {
    return new Uint8Array(32)
  }
}

export const toHoleBigInt = (numb, decimal) => {
  const numericDecimal = Number(decimal)

  const parts = numb.split('.')
  if (parts.length === 1 || parts[1] === '') {
    let addZeros = ''
    for (let index = 0; index < numericDecimal; index++) {
      addZeros = '0' + addZeros
    }
    return BigInt(parts[0].replace(/,/g, '') + addZeros)
  } else {
    const hole = parts[0].replace(/,/g, '')
    let dec = parts[1]
    if (dec.length > numericDecimal) {
      dec = dec.slice(0, numericDecimal)
    }
    let addZeros = ''
    for (let index = 0; index < numericDecimal - dec.length; index++) {
      addZeros = '0' + addZeros
    }
    return BigInt(hole + dec + addZeros)
  }
}