<template>
    <div class = "back-container">
        <a :href = "returnUrl" v-if = "returnUrl">
            <p class = "return-btn" >{{ $t('invoice.return') }}</p>
        </a>
        <p v-if = "showCancelTime" @click = "cancelJump()" class = "cancel-btn">{{ $t('cancel') }}</p>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    const getStatus = function(state) {
        let invoice = state.orderData.invoice
        let pageStatus = ''
        if(invoice) {
            if(invoice.status == 'PENDING') {
                // PENDING UNDERPAID WRONGPAID PAID
                pageStatus = invoice.crypto_info && invoice.crypto_info.paidStatus || invoice.status
            } else {
                // NEW PAID EXPIRED
                pageStatus = invoice.status
            }
        }
        return pageStatus
    }
    export default {
        data() {
            return {
                returnTimer: null,
                showCancelTime: false
            }
        },
        computed: {
            ...mapState({
                returnUrl: (state) => {
                    let returnUrl = ''
                    let pageStatus = getStatus(state)
                    let order = state.orderData.order
                    if(order) {
                        if(pageStatus == 'PAID' && order.success_url) {
                            returnUrl = order.success_url
                        } else if(pageStatus != 'PAID' && order.cancel_url) {
                            returnUrl = order.cancel_url
                        }
                    }
                    return returnUrl
                },
                pageStatus: (state) => getStatus(state)
            })
        },
        methods: {
            autoJump() {
                let h = this.$createElement;
                this.$notify({
                    title: '',
                    message: h('i', { style: ''}, this.$t('invoice.return02'))
                });
                this.showCancelTime = true
                let redirectUrl = this.returnUrl
                this.returnTimer = setTimeout(() => {
                    if (redirectUrl) {
                        window.location = redirectUrl;
                    }
                }, 5000);
            },
            cancelJump() {
                if(this.returnTimer) {
                    clearTimeout(this.returnTimer)
                }
            }   
        },
        created() {
            if(this.returnUrl && this.pageStatus == 'PAID') {
                this.autoJump()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .back-container {
        display: flex;
        justify-content: center;
        a {
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &:focus, &:active {
                text-decoration: none;
                color: #55b5ff;
            }
        }
        .return-btn {
            color: #55b5ff;
            text-align: center;
            cursor: pointer;
        }
        .cancel-btn {
            color: #55b5ff;
            text-align: center;
            cursor: pointer;
            margin-left: 6px;
        }
    }
</style>