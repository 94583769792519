<template>
    <div class = "status-container">
        <div class="status-box">
            <img src="/mediacryptos/info.svg" alt="">
            <p class = "status-text">
                {{ $t('WrongAssets') }}
            </p>
        </div>
        <p class = "status-describe" :class = "{cn: $i18n.locale == 'zh'}">
            {{ $t('WrongAssetsText') }} <span class = "mark">{{ $t('WrongAssetsTextNext') }}</span>
        </p>
        <div class = "hznKoK">
            <copy-item :labelText = "$t('AssetRequested')" :contentText = "changePayType2KeyLabel(assetText)" noneCopy = "true"></copy-item>
            <copy-item :labelText = "$t('AssetReceived')" :contentText = "changePayType2KeyLabel(assetRecievedText)" noneCopy = "true"></copy-item>
        </div>
        <order-num></order-num>
        <back></back>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import CopyItem from '../components/CopyItem'
    import OrderNum from '../components/OrderNum'
    import Back from '../components/Back'
    export default {
        components: {
            CopyItem,
            OrderNum,
            Back
        },
        computed: {
            ...mapState({
                assetText: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.pay_currency && state.orderData.invoice.pay_currency || ''
                },
                assetRecievedText: (state) => {
                    return state.orderData.invoice && state.orderData.invoice.crypto_info && state.orderData.invoice.crypto_info.paidCurrency || ''
                },
                payTypeList: (state) => state.payTypeList
            })
        },
        methods: {
            changePayType2KeyLabel(data) {
                let active
                if(data) {
                    active = this.payTypeList.filter((pay) => {
                        if(data.includes('USDT')) {
                            return pay.name == 'USDT'
                        } else {
                            return pay.key == data
                        }
                    })[0]
                } else {
                    active = ''
                }
                return active && active['keyLabel'] || ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    .status-container {
        width: 100%;
    }
    .hznKoK {
        background-color: rgba(0, 0, 0, 0.01);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .status-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
    }
    .status-describe {
        margin-bottom: 40px;
        text-align: justify;
        &.cn {
            text-align: center;
        }
    }
    .mark {
        color: #55b5ff;
    }
    /deep/ .order-num {
        margin-top: 50px;
    }
</style>